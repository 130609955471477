@if (isLoggedIn) {
  <div class="app-container">
    <div class="app-sidebar">
      <a
        class="app-logo"
        href="#">
        <img
          class="app-logo-img"
          src="assets/images/logo.svg"
          alt="AgroSell logo" />
      </a>
      <nav class="app-nav">
        @for (route of config.routes$ | async; track route.title) {
          @if (isValidGroup(route)) {
            <div class="nav-submenu-container">
              <div
                class="nav-link"
                (click)="onGroupClick(route)">
                <i
                  class="nav-icon"
                  [ngClass]="route.icon"></i
                >{{ route.title | translate }}
                <i class="nav-icon-caret ph ph-caret-down"></i>
              </div>

              <ng-container *ngIf="isGroupExpanded(route)">
                <div
                  class="nav-submenu"
                  [ngClass]="{ active: isGroupExpanded(route) }">
                  @for (children of route.children; track children.title) {
                    <a
                      class="nav-link nav-submenu-link"
                      [routerLink]="children.path"
                      [routerLinkActive]="['active']">
                      {{ children.title | translate }}
                    </a>
                  }
                </div>
              </ng-container>
            </div>
          } @else {
            <a
              class="nav-link"
              (click)="unselectExpandedGroup()"
              [routerLink]="route.path"
              [routerLinkActive]="['active']">
              <i
                class="nav-icon"
                [ngClass]="route.icon"></i
              >{{ route.title | translate }}
            </a>
          }
        }
      </nav>

      <div class="app-user">
        <lib-menu-nav-profile></lib-menu-nav-profile>
      </div>
    </div>
    <div class="app-main">
      <div class="app-header-nav">
        <lib-menu-nav [isLoggedIn]="isLoggedIn"></lib-menu-nav>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <div class="app-container">
    <div class="app-main">
      <div class="app-header-nav">
        <lib-menu-nav [isLoggedIn]="isLoggedIn"></lib-menu-nav>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
}
